<!--
 * @Description: 载体类型切换弹窗
 * @version:
-->
<template>
  <div class="container">
    <my-popup heightStyle="calc(60vh)" @closeMask="CARRIER_SWITCH_STATUS" :checked="carrierSwitchStatus">
      <!-- 载体选择区域 -->
      <van-tabs v-model:active="active" @change="handleCarrier" color="#3F3F3F" title-inactive-color="#ababab" title-active-color="#3F3F3F" swipeable>
        <van-tab v-for="(item, index) in carrierInfoList" :title="item.cate_name" :key="index">
          <div class="product_container">
            <better-scroll ref="betterTarget" :isPullUpLoad="false" style="height: calc(60vh - 60px)">
              <van-loading v-show="showLoading" class="z-40 absolute top-1/4 left-1/2 -translate-x-1/2 -translate-y-1/2" vertical color="#3F3F3F">加载中...</van-loading>
              <block v-show="!showLoading">
                <product-card @itemDetail="getCarrierType" class="card_item" :key="i.id" :cardItem="i" v-for="i in CategoryProductList"></product-card>
              </block>
              <div style="height: 50px"></div>
            </better-scroll>
          </div>
        </van-tab>
      </van-tabs>
    </my-popup>
    <my-popup @closeMask="closeMainMask" heightStyle="calc(93vh - 20px)" :checked="carrierSwitchMain">
      <carrier-detail-info
        @changeSize="getCurrentSize"
        :selectSizeIndex="selectSizeIndex"
        @changeSizeIndex="getSizeIndex"
        :productSize="productSize"
        @sizeStatus="changeSizeStatus"
        @confirmStatus="carrierType"
        :isSizeStatus="isSizeStatus"
        :carrierList="carrierList"
        :colorIndex="currentColorIndex"
        @changeColor="onChangeColor"
      ></carrier-detail-info>
    </my-popup>
  </div>
</template>

<script>
import { setItem, getItem } from "@/utils/utils";
import myPopup from "@/components/common/myPopup/myPopup.vue";
import betterScroll from "@/components/common/betterScroll";
import productCard from "./components/productCard";
import carrierDetailInfo from "./components/carrierDetailInfo.vue";
import { getCategory, getCategoryProductList, getCategoryDetail, carrierDetailList } from "@/api/app";
import { mapState, mapMutations } from "vuex";
export default {
  data() {
    return {
      showLoading: false,
      active: 0,
      categoryId: 0,
      categoryList: [],
      carrierInfoList: [],
      CategoryProductList: [],
      carrierList: [],
      carrierIndex: 0,
      tabCurrentIndex: 0,
      cateId: 0,
      isShow: false,
      productId: 0,
      isSizeStatus: true,
      productSize: "",
      selectSizeIndex: 0,
      is_Child: "",
      currentColorIndex: 0,
    };
  },
  components: {
    myPopup,

    productCard,
    betterScroll,
    carrierDetailInfo,
  },
  computed: {
    ...mapState(["carrierSwitchStatus", "carrierSwitchMain", "seriesId", "mcId", "isChild", "isIndex", "actName", "currentCateId"]),
  },
  created() {
    if (getItem("activityCateId")) this.cateId = getItem("activityCateId");

    this.getinfoAll();
  },
  methods: {
    ...mapMutations(["CARRIER_SWITCH_STATUS", "CARRIER_SWITCH_MAIN", "SET_CHILD_STATUS", "CHANGE_MENU_STATUS", "SET_CARRIER_SIZE"]),
    getCarrierType(value) {
      let { is_child, item } = value;
      this.is_Child = is_child;
      this.handleClickProduct(item);
    },

    carrierType() {
      //储存当前的载体尺码
      this.SET_CARRIER_SIZE(this.productSize);
      if (this.actName != "mini") {
        console.log("当前不是亲子活动");
        this.SET_CHILD_STATUS(this.is_Child);
        setItem("isChild", this.is_Child);
        this.$bus.$emit("refresh");
      } else {
        console.log("当前是亲子活动");
      }
    },
    getCurrentSize(size) {
      this.productSize = size;
    },
    getSizeIndex(index) {
      this.selectSizeIndex = index;
    },
    changeSizeStatus(status) {
      // this.productSize = "";
      //尺码表状态
      this.isSizeStatus = status;
    },
    closeMainMask() {
      setTimeout(() => {
        this.isSizeStatus = true;
        this.productSize = "";
      }, 300);

      this.CARRIER_SWITCH_MAIN();
    },
    handleClickProduct(item) {
      this.productId = item.id;
      this.CARRIER_SWITCH_MAIN();
      this.carrierDetailList();
    },
    async getinfoAll() {
      await this.getCategoryList();
      await this.getCategoryDetail();
      await this.getCategoryProductList();
      // await this.carrierDetailList();
    },
    getCategoryList() {
      const params = {
        id: 0,
        params: {},
      };

      this.seriesId && (params.params.series_id = this.seriesId);
      this.isChild && (params.params.is_child = this.isChild);
      this.isIndex && (params.params.is_index = this.isIndex);
      this.mcId && (params.params.custom_id = this.mcId);
      this.cateId && (params.params.cid = this.cateId);

      console.log("请求封神的载体", params);
      return getCategory(params).then(res => {
        this.categoryList = res.data.data;
        this.categoryId = this.categoryList[this.tabCurrentIndex].id;
      });
    },
    getCategoryDetail() {
      const params = {
        id: this.categoryId,
        params: {},
      };
      this.seriesId && (params.params.series_id = this.seriesId);
      this.isIndex && (params.params.is_index = this.isIndex);

      this.mcId && (params.params.custom_id = this.mcId);
      this.isChild && (params.params.is_child = this.isChild);
      this.currentCateId && (params.params.cid = this.currentCateId);
      this.cateId && (params.params.cid = this.cateId);

      //       【ID1000345】
      // 更改载体，可以选择所有载体

      return getCategoryDetail(params).then(res => {
        this.carrierInfoList = res.data.data;
        let filterIndex = this.carrierInfoList.findIndex(item => {
          return item.is_check === 1;
        });
        if (filterIndex > -1) {
          this.carrierIndex = filterIndex;
        }
        this.cateId = this.carrierInfoList[this.carrierIndex].id;
      });
    },
    getCategoryProductList() {
      this.showLoading = true;

      const params = {
        id: this.cateId,
        params: {},
      };
      this.isIndex && (params.params.is_index = this.isIndex);
      this.seriesId && (params.params.series_id = this.seriesId);
      this.mcId && (params.params.custom_id = this.mcId);
      this.isChild && (params.params.is_child = this.isChild);
      return getCategoryProductList(params).then(res => {
        this.CategoryProductList = res.data.data.list;
        this.productId = res.data.data.list[0]?.id;
        this.showLoading = false;
        this.$refs.betterTarget[0].refresh();
      });
    },
    // 点击选择载体
    handleCarrier() {
      this.showLoading = true;
      let index = this.active;
      let item = this.carrierInfoList[index];
      this.cateId = item.id;
      this.carrierIndex = index;
      this.getCategoryProductList();
      //切换载体类型必须重置尺码
      this.currentColorIndex = 0;
    },
    // 点击tab接受index
    async handleTab(id, index) {
      this.categoryId = id;
      this.tabCurrentIndex = index;
      this.carrierIndex = 0;
      await this.getCategoryDetail();
      await this.getCategoryProductList();
    },
    // 获取商品列表
    carrierDetailList() {
      let config = {};
      if (this.mcId) {
        config.custom_id = this.mcId;
      } else {
        config.custom_id = 0;
      }
      if (this.seriesId) {
        config.series_id = this.seriesId;
      }
      config.product_id = this.productId;
      return carrierDetailList(config).then(res => {
        this.carrierList = [];
        this.carrierList = res.data.data.list[0];
        this.categoryId = 0;
        // if (this.productSize) return;
        let firstItem = res.data.data.list[0].productAttr;
        if (firstItem.length <= 1) {
          this.productSize = firstItem[0].attr_values[0];
        } else {
          this.productSize = firstItem[1].attr_values[0];
        }
        // this.selectSizeIndex = 0;
      });
    },
    onChangeColor(currentIndex) {
      this.currentColorIndex = currentIndex;
    },
  },
};
</script>

<style lang="scss" scoped>
canvas {
  will-change: transform;
  transform: translateZ(0);
}
.container {
  background-color: #fff;
  z-index: 9999;
  .tab_control {
    color: #ababab;
    background-color: white;
    height: 44px;
    box-sizing: border-box;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;

    border-bottom: 1px solid #f5f5f5;

    .tab_item {
      box-sizing: border-box;
      padding: 0 20px;
      height: 44px;
      line-height: 44px;
      font-size: 14px;
      font-weight: 500;
      position: relative;

      .line_bottom {
        box-sizing: border-box;

        height: 3x;
        width: 15px;
        transition-duration: 500ms;
        position: absolute;
        bottom: 2px;
        left: 50%;
        transform: translate(-50%);
        border-radius: 1.5px;
      }
    }
  }
  .carrier_container ::-webkit-scrollbar {
    /*隐藏滚轮*/
    display: none;
  }
  ::-webkit-scrollbar {
    display: none;
  }
  .carrier_container {
    box-sizing: content-box;
    position: relative;
    background-color: white;
    width: 100%;
    height: 48px;
    display: flex;
    // justify-content: space-evenly;
    text-align: center;
    white-space: nowrap;
    overflow-x: scroll;
    overflow-y: hidden;
    align-items: center;
    /*解决ios上滑动不流畅*/
    -webkit-overflow-scrolling: touch;
    .carrier_item {
      padding: 0 20px;
      display: inline-block;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      align-items: center;
      .item_text {
        overflow: hidden;
        margin-bottom: 16rpx;
        font-size: 14px;
        line-height: 20px;
        font-weight: 500;
        color: #ababab;
      }
      .line_style {
        position: absolute;
        bottom: 1px;
        height: 3px;
        width: 15px;
        border-radius: 1.5px;
      }
    }
    .carrier_active .line_style {
      background-color: #3f3f3f !important;
    }
    .carrier_active .item_text {
      color: #3f3f3f;
    }
  }
  .product_container {
    box-sizing: border-box;
    padding: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 12px;
    background: #f7f7f7;
    .card_item {
      margin-bottom: 16px;
    }
  }
}
</style>
